<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      class="dialog-box"
      v-model="dialog"
      max-width="1500px"
      height="auto"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="dialog = false"
      content-class="overflow-hidden"
    >
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div style="text-align: center">
            <h1>{{ title }}</h1>
          </div>

          <v-toolbar flat elevation="1" style="margin-top: 3px">
            <h2>{{ well.wellname }}</h2>
            <v-spacer></v-spacer>
            <v-btn class="ma-1" color="blue darken-1">PDF</v-btn>
            <v-btn class="ma-1" color="blue darken-1" @click.stop="close"
              >Close</v-btn
            >
          </v-toolbar>
          <div
            id="page"
            style="
              width: 100%;
              height: 100vh;
              overflow-y: auto;
              margin-top: 6px;
            "
          >
            <v-row>
              <v-col cols="5" sm="5" md="5">
                <v-checkbox
                  v-model="cem_log"
                  label="Show Cement Log"
                  @change="ksvg1++"
                  v-if="corrosion"
                ></v-checkbox>
                <v-checkbox
                  v-model="cor_log"
                  label="Show Corrosion Log"
                  @change="ksvg1++"
                  v-if="cement"
                ></v-checkbox>
                <wellsubsurface
                  :formations="formations_list.filter((elm) => elm.active == 1)"
                  :show_cement="cem_log"
                  :show_cor="cor_log"
                  :unit="$store.state.options[0].depth"
                  :well="well"
                  :key="ksvg1"
                  :csgs="tubulars_list"
                ></wellsubsurface>
              </v-col>

              <v-col cols="7" sm="6" md="7">
                <div style="margin-top: 40px" v-if="corrosion">
                  <h2>Corrosion test</h2>
                  <br />
                  <div id="vCorrosion">
                    <listitems
                      :title="'Corrosion'"
                      :list="corrosion_list"
                      :toolbar="false"
                      :headers="corrosionheaders"
                      :key="kcorrosion"
                      :showstd="true"
                      :add="false"
                      :del="false"
                      :hdf="true"
                      :ipg="-1"
                      :elevation="10"
                      :multiple="false"
                      :kid="'id'"
                    >
                    </listitems>
                  </div>
                </div>
                <div style="margin-top: 40px" v-if="cement">
                  <h2>Cements test</h2>
                  <br />
                  <div id="vCement">
                    <listitems
                      :title="'Cement'"
                      :list="cement_list"
                      :toolbar="false"
                      :headers="cementheaders"
                      :key="kcement"
                      :showstd="true"
                      :add="false"
                      :del="false"
                      :hdf="true"
                      :ipg="-1"
                      :elevation="10"
                    >
                    </listitems>
                  </div>
                </div>
                <waiter :showWait="showWait"> </waiter>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import CMP_BARRIERS from "../graphql/Tests/CMP_BARRIERS.gql";
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    waiter: () => import("../components/Widgets/Waiter.vue"),

    wellsubsurface: () => import("../components/svg/wellsubsurface.vue"),
  },
  props: {
    well_rapport: Object,
    corrosion: { type: Boolean, default: false },
    cement: { type: Boolean, default: false },
    dialog: Boolean,
  },
  data() {
    return {
      cem_log: false,
      formations_list: [],
      tubulars_list: [],
      cor_log: true,
      ksvg1: 10000,
      well: {},
      title: "Well Corrosion Report",
      showWait: false,
      dialogform: false,
      corrosion_list: [],
      cement_list: [],
      kcorrosion: 100,
      kcement: 5000,
      cementheaders: [
        {
          text: "Date",
          value: "testdate",
          selected: true,
        },
        {
          text: "String",
          value: "sizes",
          selected: true,
        },
        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
        },
        {
          text: "Evaluation",
          value: "level",
          slot: "chip",
          color: "color",
          selected: true,
        },
      ],
      corrosionheaders: [
        {
          text: "Id",
          value: "id",
          selected: false,
        },
        {
          text: "Date",
          value: "testdate",
          selected: true,
        },
        {
          text: "String",
          value: "sizes",
          selected: true,
        },
        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
        },
        {
          text: "Evaluation",
          value: "evaluation",
          slot: "progress",
          color: "color",
          selected: true,
        },
        {
          text: "Level",
          value: "level",
          slot: "chip",
          color: "color",
          selected: true,
        },
      ],
    };
  },
  watch: {},
  async mounted() {
    if (this.dialog)
      if (this.well_rapport) {
        this.well = Object.assign({}, this.well_rapport);
        if (this.cement) this.title = "Well Cement Report";
        this.formations_list = this.well.wellsformations
          ? this.well.wellsformations
          : [];
        this.tubulars_list = this.well.wellstubulars
          ? this.well.wellstubulars
          : [];
        this.cem_log = this.cement;
        this.cor_log = this.corrosion;

        this.ksvg1++;
        this.getdata();
      }
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    async getdata() {
      this.showWait = true;

      let r3 = await this.$requette(
        CMP_BARRIERS,
        {
          WellID: parseInt(this.well.id),
        },
        "no-cache"
      );
      if (r3.ok) {
        //

        this.corrosion_list = r3.data.test_corrosions_list;
        this.cement_list = r3.data.test_cements_list;
        this.kcorrosion++;
        this.kcement++;
      }
      this.showWait = false;
    },
  },
};
</script>
